<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <el-row>
        <el-col :md="14">
          <el-button type="success"  size="small" disabled>编辑</el-button>
          <el-button type="primary" size="small" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
        </el-col>
        <el-col :md="10" class="vd_export2">
          <span class="vg_tag_position">
            <span class="vg_tag_label">状态：</span>
            <el-tag :type="helper.getStatusName(suppForm.status).type">{{helper.getStatusName(suppForm.status).name}}</el-tag>
          </span>
        </el-col>
      </el-row>
    </div>
    <el-form ref="suppForm" :model="suppForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row  class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="供应商编号" prop="supp_no">
            <!-- <el-input v-model="suppForm.supp_no" @input ="suppForm.supp_no = helper.keepEngNum1(suppForm.supp_no)" placeholder="请填写账套编号（仅支持数字和英文和-）" minlength="30"/> -->
            <el-input maxlength="10"  @input="suppForm.supp_no = (helper.pureEC(suppForm.supp_no)).toUpperCase()" v-model="suppForm.supp_no" placeholder="请填写供应商编号" show-word-limit clearable>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商全称" prop="supp_name">
            <el-input v-model="suppForm.supp_name" maxlength="30" show-word-limit placeholder="请填写供应商全称">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_abbr">
            <el-input v-model="suppForm.supp_abbr" maxlength="10" show-word-limit placeholder="请填写供应商简称" clearable>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="供应商类型" prop="supp_type">
            <el-select v-model="suppForm.supp_type" placeholder="请选择供应商类型" size="mini" clearable show-word-limit>
              <el-option v-for="item in suppType" :key="item.id" :label="item.label" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="联系人姓名" prop="supp_contact">
            <el-input v-model="suppForm.supp_contact" maxlength="20" show-word-limit placeholder="请填写联系人" clearable>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :md="8">
          <el-form-item label="联系电话" prop="supp_phone">
            <el-input v-model="suppForm.supp_phone"
              @input="suppForm.supp_phone = helper.pureNumber(suppForm.supp_phone)" maxlength="50" show-word-limit
              clearable placeholder="请填写联系电话"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="采购经办人" prop="purc_stff_id">
            <el-select filterable v-model="suppForm.purc_stff_id" placeholder="请选择采购经办人" size="mini" clearable show-word-limit>
              <el-option
                  v-for="item in stffList"
                  :key="item.stff_id"
                  :label="item.stff_name"
                  :value="item.stff_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> 
        <el-col :md="8">
          <el-form-item label="供应商地址" prop="supp_addr">
            <el-input v-model.trim="suppForm.supp_addr"  type="textarea" autosize
              clearable maxlength="50" show-word-limit placeholder="请填写供应商地址"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="suppForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { suppAPI } from "@api/modules/supp";
import { optnAPI } from "@api/modules/optn";
import { stffAPI } from "@/api/modules/staff";
import inputUser from "@/views/component/inputUser";

export default {
  name: 'suppEditMain',
  components: {
    inputUser
  },
  data() {
    return {
      rules: {
        supp_no: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        supp_name: [
          { required: true, trigger: 'blur', message: ' ' },
        ],
        supp_type: [
          { required: true, trigger: 'change', message: ' ' },
        ],
      },
      suppForm: {
        supp_no: null,
        supp_name: null,
        supp_type: null,
        supp_addr: null,
        supp_contact: null,
        supp_phone: null,
        supp_abbr: null,
        purc_stff_id: null,
        supp_maincate: null, //主营类目
      },
      isShow: true,
      btn: {},
      stffForm: {},
      suppType: [
        { id: 1, label: '加工厂' },
        { id: 2, label: '面料厂' },
        { id: 3, label: '配套厂' },
        { id: 4, label: '辅料厂' },
      ],
      loadingFlag: false,
      stffList:[],
    }
  },
  created() {
    this.initData()
  },
  watch: {
  },
  methods: {
    initData() {
      this.loadingFlag = true
      this.getSuppInfo();
      this.getStffUser()
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow)
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.$emit('isShow', this.isShow)
        this.initData()
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      }).catch(() => { })
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            this.$message.warning("请把必填内容补充完整");
            return false;
          }
        });
      }).catch(() => { })
    },
    // 保存
    saveInfo() {
      post(suppAPI.editSupp, this.suppForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            })
            this.isShow = true;
            this.$emit('isShow', this.isShow)
            this.initData()
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '供应商编号重复'
            })
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData()
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData()
    },

    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 指定外销员list
    getStffUser() {
      get(stffAPI.getAllStffsV1).then(res => {
        if (res.data.code === 0) {
          this.stffList = res.data.data
          return
        }
        this.$message.error(res.data.msg)
      }).catch(res => {
        this.$message.error(res.data.msg)
      })
    },
    // 获取表单信息
    getSuppInfo() {
      console.log(1111);
      this.suppForm.supp_id = this.$route.query.form_id
      get(suppAPI.getSuppById, { supp_id: this.suppForm.supp_id })
        .then(res => {
          if (res.data.code === 0) {
            this.suppForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.suppForm.stff_name;
            this.stffForm.dept_name = this.suppForm.dept_name;
            this.stffForm.dept_team_name = this.suppForm.dept_team_name;
            this.stffForm.user_id = this.suppForm.user_id;
            this.stffForm.dept_id = this.suppForm.dept_id;
            this.stffForm.stff_id = this.suppForm.stff_id;
            setTimeout(() => {
              this.loadingFlag = false
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          console.log('res',res);
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
    }
  }
}
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}

.vd_edhea {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
