import { render, staticRenderFns } from "./SuppEdit.vue?vue&type=template&id=f27f7744&scoped=true&"
import script from "./SuppEdit.vue?vue&type=script&lang=js&"
export * from "./SuppEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f27f7744",
  null
  
)

export default component.exports