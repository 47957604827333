import { render, staticRenderFns } from "./SuppEditMain.vue?vue&type=template&id=1454364e&scoped=true&"
import script from "./SuppEditMain.vue?vue&type=script&lang=js&"
export * from "./SuppEditMain.vue?vue&type=script&lang=js&"
import style0 from "./SuppEditMain.vue?vue&type=style&index=0&id=1454364e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1454364e",
  null
  
)

export default component.exports